import { createSlice } from "@reduxjs/toolkit";
import { query } from "@services/api";

import { axiosGetWithNext } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { mapUsers } from "./maps";

let initialState = {
  isLoading: false,
  isUserLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  isAllUsersLoading: false,
  userList: [],
  allUserList: [],
  triggerCSVDownload: false,
  nextPage: null,
  nextLink: null,
  currentUser: {
    id: null,
    role: null,
    status: null,
    name: null,
    email: null,
    isOnPremise: null,
    isRetail: null,
    states: [],
    territories: [],
  },
  updateStatus: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isUserLoading = false;
  state.isNextLoading = false;
  state.isUpdateLoading = false;
  state.isAllUsersLoading = false;
  state.error = error;
};

const resetCurrentUser = (state) => {
  state.currentUser.id = null;
  state.currentUser.role = null;
  state.currentUser.status = null;
  state.currentUser.name = null;
  state.currentUser.email = null;
  state.currentUser.isOnPremise = null;
  state.currentUser.isRetail = null;
  state.currentUser.states = [];
  state.currentUser.territories = [];
};

const userUpdateSlice = createSlice({
  name: "userUpdate",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getUsersSuccess(state, action) {
      const { users, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.userList = [...users];
      state.isLoading = false;
      state.error = null;
    },
    clearUserUpdate(state) {
      state.isLoading = false;
      state.isUserLoading = false;
      state.isNextLoading = false;
      state.isUpdateLoading = false;
      state.nextPage = null;
      state.nextLink = null;
      state.userList = [];
      state.allUserList = [];
      state.triggerCSVDownload = false;
      state.updateStatus = false;
      state.error = null;
      resetCurrentUser();
    },
    setFailure: loadingFailed,
  },
});

export const { setIsLoading, getUsersSuccess, clearUserUpdate, setFailure } =
  userUpdateSlice.actions;

export default userUpdateSlice.reducer;

export const fetchFilteredUsers =
  ({ name = "", roles, status, isProgramCreator }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const queryString = `/api/users?${query({
        filter: {
          nameOrEmail: name,
          roles,
          status,
          isProgramCreator,
        },
      })}`;
      const response = await axiosGetWithNext(queryString);
      if (response.error) throw response.error;
      let mappedData = mapUsers(response.data.data);
      dispatch(
        getUsersSuccess({
          users: mappedData,
          nextLink: response.data.nextLink,
        })
      );
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "User Update" }));
    }
  };
