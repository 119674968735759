import { createSlice } from "@reduxjs/toolkit";

import { axiosGetWithNext, axiosPost } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { setSorted } from "../filterSlice";
import {
  resetStepperValue,
  setIsStepper,
  updateStepperValue,
} from "../globalLoadSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildItemProgramQuery, buildItemQuery } from "./helpers";
import { mapItemReport, mapItems } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  orderType: null,
  itemsPerPage: 20,
  totalPages: null,
  pagesLoaded: 0,
  nextLink: null,
  items: [],
  selectedItems: [],
  itemReport: [],
  currentItem: null,
  hasUpdated: false,
  triggerCSVDownload: false,
  error: null,
  itemPreviewId: null,
  itemPreviewFocusImageId: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isUpdateLoading = false;
  state.error = error;
};

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setItemPreview(state, action) {
      const { id, focusImageId } = action.payload;
      state.itemPreviewId = id;
      state.itemPreviewFocusImageId = focusImageId
        ? String(focusImageId)
        : null;
    },
    getItemsSuccess(state, action) {
      const { orderType, items, nextLink } = action.payload;
      state.orderType = orderType;
      state.pagesLoaded += 1;
      state.nextLink = nextLink;
      state.items = [...items];
      state.isLoading = false;
      state.error = null;
    },
    getNextItemsSuccess(state, action) {
      const { items, nextLink } = action.payload;
      let currentItems = [...state.items];
      let updatedItems = currentItems.concat(items);
      state.pagesLoaded += 1;
      state.nextLink = nextLink;
      state.items = updatedItems;
      state.isNextLoading = false;
      state.error = null;
    },
    getItemSuccess(state, action) {
      const { item, setUpdated } = action.payload;
      state.currentItem = item;
      state.isLoading = false;
      state.isUpdateLoading = false;
      if (setUpdated) {
        state.hasUpdated = true;
      }
      state.error = null;
    },
    getItemReportSuccess(state, action) {
      const { items } = action.payload;
      state.itemReport = items;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    clearItemReport(state) {
      state.itemReport = [];
    },
    updateItemSelection(state, action) {
      const { selectedItems } = action.payload;
      state.selectedItems = selectedItems;
    },
    clearItems(state) {
      state.items = [];
    },
    clearItemSelection(state) {
      state.selectedItems = [];
    },
    clearCurrentItem(state) {
      state.currentItem = null;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    resetItems(state) {
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.isNextLoading = false;
      state.orderType = null;
      state.itemsPerPage = 20;
      state.totalPages = null;
      state.pagesLoaded = 0;
      state.nextLink = null;
      state.items = [];
      state.selectedItems = [];
      state.currentItem = null;
      state.hasUpdated = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setItemPreview,
  getItemsSuccess,
  getNextItemsSuccess,
  getItemSuccess,
  updateItemSelection,
  getItemReportSuccess,
  setTriggerCSVFalse,
  clearItemReport,
  clearItems,
  clearItemSelection,
  clearCurrentItem,
  setHasUpdated,
  resetItems,
  setFailure,
} = itemSlice.actions;

export default itemSlice.reducer;

export const fetchItemReport = (filterObject, type) => async (dispatch) => {
  try {
    dispatch(
      setIsStepper({
        stepBool: true,
        stepTitle: "Generating Item Report",
      })
    );

    const itemProgramQuery =
      filterObject.orderCalendarMonthIds && type === "pdf";
    const mapFunction = itemProgramQuery ? mapItemReport : mapItems;
    let itemArray = [];
    let stepValue = 10;

    const queryString = itemProgramQuery
      ? buildItemProgramQuery(filterObject)
      : buildItemQuery(filterObject);
    let initialResponse = await axiosGetWithNext(queryString);

    if (initialResponse.error) throw initialResponse.error;
    let initialMappedData = mapFunction(initialResponse.data.data);
    itemArray = itemArray.concat(initialMappedData);

    let nextLink = initialResponse.data.nextLink;
    dispatch(updateStepperValue({ value: stepValue }));

    if (nextLink) {
      const pageSize = initialResponse.data.data.length;
      let fetchCount =
        Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
      stepValue = parseFloat((90 / fetchCount).toFixed(2));

      for (let i = 0; i < fetchCount; i++) {
        let nextResponse = await axiosGetWithNext(nextLink);
        if (nextResponse.error) throw nextResponse.error;
        nextLink = nextResponse.data.nextLink;
        let mappedData = mapFunction(nextResponse.data.data);
        itemArray = itemArray.concat(mappedData);
        dispatch(updateStepperValue({ value: stepValue }));
      }
    } else {
      dispatch(updateStepperValue({ value: 90 }));
    }

    dispatch(resetStepperValue());
    if (type === "pdf") {
      dispatch(
        updateItemSelection({ selectedItems: itemArray.map((i) => i.id) })
      );
      dispatch(getItemsSuccess({ items: itemArray }));
    } else {
      dispatch(getItemReportSuccess({ items: itemArray }));
    }
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Item Reports" }));
    dispatch(resetStepperValue());
  }
};

export const updateItemSupplier = (id, supplierId) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    const postData = {
      data: {
        supplier_id: supplierId,
      },
    };
    const response = await axiosPost(
      `/api/items/${id}/update-supplier`,
      postData
    );
    if (response.error) throw response.error;
    dispatch(setSorted());
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Item Supplier Update" })
    );
  }
};
