/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Close, Edit } from "@mui/icons-material";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { formatAddress } from "@features/addresses";
import Tags from "@features/ui/Tags";
import { Order } from "@models/Order";

import { displayToInventoryAllocation } from "../helpers";
import EditOrderDetailsModal from "./EditOrderDetailsModal";
import InputCell from "./InputCell";
import { useCurrentOrderSet } from "./data/CurrentOrderSetContext";
import useDeleteOrder from "./data/mutations/useDeleteOrder";

const DeleteOrderButton = ({ handleDeleteOrder }) => (
  <Tooltip title="Delete Order">
    <IconButton
      edge="end"
      size="small"
      onClick={handleDeleteOrder}
      tw="bg-neutral-100 hover:bg-neutral-200 rounded p-0.5"
    >
      <Close fontSize="small" />
    </IconButton>
  </Tooltip>
);

const OrderSetOrders = ({ stateCodeFilter }) => {
  const { orders, orderSetVariants, isFetching } = useCurrentOrderSet();

  const deleteOrder = useDeleteOrder();

  const filteredOrders = stateCodeFilter
    ? orders.filter((order) =>
        order.address ? order.address.state.code === stateCodeFilter : true
      )
    : orders;

  const ovIds = useRef<string[]>([]);
  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({});
  const [editingOrder, setEditingOrder] = useState<null | Order>(null);

  const { reset } = useFormContext();

  const handleKeyEvent = useCallback(
    (evt) => {
      const { name: id } = evt.target;
      if (
        evt.key === "Enter" ||
        evt.key === "ArrowUp" ||
        evt.key === "ArrowDown" ||
        evt.key === "ArrowLeft" ||
        evt.key === "ArrowRight"
      ) {
        evt.preventDefault();
        const cellIndex = ovIds.current.indexOf(id);
        if (cellIndex === -1) {
          window.removeEventListener("keydown", handleKeyEvent);
          return;
        }
        const gridWidth = orderSetVariants.length;
        let nextCellIndex = cellIndex;
        let nextCellId: string;
        do {
          if (evt.key === "ArrowUp") {
            nextCellIndex -= gridWidth;
          } else if (evt.key === "ArrowDown" || evt.key === "Enter") {
            nextCellIndex += gridWidth;
          } else if (evt.key === "ArrowLeft") {
            nextCellIndex -= 1;
          } else if (evt.key === "ArrowRight") {
            nextCellIndex += 1;
          }
          nextCellId = ovIds.current[nextCellIndex];
        } while (
          // Skips over non-compliant cells (they don't have an input ref)
          !inputRefs.current[nextCellId] &&
          // Stop if we've reached the end of the grid
          // avoids infinite loop
          nextCellIndex <= ovIds.current.length - 1 &&
          nextCellIndex >= 0
        );

        if (nextCellId) {
          window.removeEventListener("keydown", handleKeyEvent);
          // Focus next cell
          inputRefs.current[nextCellId]?.focus();
        }
      }
      if (evt.key === "Tab") {
        window.removeEventListener("keydown", handleKeyEvent);
      }
    },
    [orderSetVariants.length]
  );

  const handleDeleteOrder = (id) => deleteOrder.mutate(id);

  useEffect(() => {
    ovIds.current = filteredOrders.flatMap((order) =>
      order.orderVariants.map((ov) => ov.id)
    );
  }, [filteredOrders, orderSetVariants]);

  useEffect(() => {
    if (isFetching) return;
    const formValues = orders.reduce((acc, order) => {
      order.orderVariants.forEach((ov) => {
        acc[ov.id] = String(ov.qty);
      });
      return acc;
    }, {});

    reset(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <>
      <TableBody>
        {filteredOrders.map((order) => (
          <TableRow key={order.id}>
            <TableCell>
              <div tw="grid grid-cols-[auto 1fr auto] items-center">
                {order.type === "to-inventory" ? (
                  <>
                    <Tags.Restock brandFunded={order.isBrandFunded} />
                    <Tooltip
                      placement="right"
                      title={displayToInventoryAllocation(order)}
                    >
                      <Typography noWrap>
                        {displayToInventoryAllocation(order)}
                      </Typography>
                    </Tooltip>
                    <DeleteOrderButton
                      handleDeleteOrder={() => handleDeleteOrder(order.id)}
                    />
                  </>
                ) : (
                  <>
                    <div tw="px-1 py-0.5 mr-2 text-xs font-medium tracking-wider rounded bg-neutral-200">
                      {order.address.state.code}
                    </div>
                    <Tooltip
                      placement="right"
                      title={`${formatAddress(order.address)}${
                        order.attn ? `. ATTN: ${order.attn}` : ""
                      }`}
                    >
                      <Typography noWrap>
                        {order.address.name}: {formatAddress(order.address)}
                      </Typography>
                    </Tooltip>
                    <div tw="flex items-center gap-1 text-neutral-600">
                      <Tooltip title="Edit Order">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => setEditingOrder(order)}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <DeleteOrderButton
                        handleDeleteOrder={() => handleDeleteOrder(order.id)}
                      />
                    </div>
                  </>
                )}
              </div>
            </TableCell>
            {order.orderVariants.map((ov) => (
              <TableCell
                key={ov.id}
                tw="relative focus-within:(z-10 outline outline-2 -outline-offset-2 outline-blue-400)"
              >
                <InputCell
                  inputRefs={inputRefs}
                  orderVariant={ov}
                  handleKeyEvent={handleKeyEvent}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
      {editingOrder && (
        <EditOrderDetailsModal
          order={editingOrder}
          handleClose={() => setEditingOrder(null)}
        />
      )}
    </>
  );
};

export default OrderSetOrders;
