/** @jsxImportSource @emotion/react */
import "twin.macro";

import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteValue,
  CircularProgress,
  InputAdornment,
  LinearProgress,
  Paper,
  PaperProps,
  Typography,
} from "@mui/material";

import { debounce, uniqBy } from "lodash";
import { DefaultTextField } from "src/components/Forms/DefaultInputs";

import { Address } from "@models";

import { useAddressesQuery } from "./addressQueries";
import { formatAddress } from "./helpers";

const insureArray = <T extends any>(value: T | T[] | null | undefined) =>
  Array.isArray(value)
    ? value
    : value === null || value === undefined
      ? []
      : [value];

const getLabel = (address: Address) => {
  return address.type === "custom"
    ? `c${address.id} ${address.name}`
    : `${address.abn ?? ""} ${address.name}`;
};

const filterAddresses = (options: Address[], inputValue: string) => {
  const lowerCasedInputValue = inputValue.toLowerCase();

  // This should match the logic for the search filter in address_controller in the backend.
  return options.filter((option) => {
    const nameMatches = option.name
      .toLowerCase()
      .includes(lowerCasedInputValue);
    const abnMatches = option.abn?.toLowerCase().includes(lowerCasedInputValue);
    const idMatches = `c${option.id}`.includes(lowerCasedInputValue);
    const streetAddress1Matches = option.streetAddress1
      ?.toLowerCase()
      .includes(lowerCasedInputValue);
    const streetAddress2Matches = option.streetAddress2
      ?.toLowerCase()
      .includes(lowerCasedInputValue);
    const cityMatches = option.city
      ?.toLowerCase()
      .includes(lowerCasedInputValue);
    const stateCodeMatches = option.state?.code
      ?.toLowerCase()
      .includes(lowerCasedInputValue);

    return (
      nameMatches ||
      abnMatches ||
      idMatches ||
      streetAddress1Matches ||
      streetAddress2Matches ||
      cityMatches ||
      stateCodeMatches
    );
  });
};

type PaperComponentProps = {
  isLoading: boolean;
  optionCount: number;
  children?: ReactNode;
} & PaperProps;

const PaperComponent = ({
  isLoading,
  optionCount,
  children,
  ...props
}: PaperComponentProps) => (
  <Paper {...props} tw="relative">
    {children}
    {optionCount === 20 && !isLoading && (
      <div tw="text-sm text-neutral-500 px-3 py-1">Showing top 20 results</div>
    )}
    {isLoading && <LinearProgress tw="absolute bottom-0 left-0 right-0" />}
  </Paper>
);
type AddressSearchProps<Multiple extends boolean> = {
  onChange: (a: AutocompleteValue<Address, Multiple, false, false>) => void;
  reset?: boolean;
  setReset?: (b: boolean) => void;
  autoFocus?: boolean;
  currentTerritory?: boolean;
  filters?: Record<string, any>;
} & Omit<
  AutocompleteProps<Address, Multiple, false, false, any>,
  "onChange" | "renderInput" | "options"
>;

const AddressSearch = <Multiple extends boolean = false>({
  onChange,
  reset,
  setReset,
  multiple,
  autoFocus = false,
  currentTerritory,
  filters,
  ...props
}: AddressSearchProps<Multiple>) => {
  const emptyValue = (multiple ? [] : null) as AutocompleteValue<
    Address,
    Multiple,
    false,
    false
  >;
  const [address, setAddress] = useState(emptyValue);
  const [searchTerm, setSearchTerm] = useState("");

  const { currentTerritory: currentTerritoryId } = useSelector(
    (state: any) => state.user
  );
  const { data, isLoading } = useAddressesQuery({
    search: searchTerm,
    isActive: true,
    territoryId: currentTerritory ? currentTerritoryId : undefined,
    ...filters,
  });
  const addresses = (data ?? []).filter((a) => a.type !== "warehouse");
  const options = uniqBy(
    [...insureArray(props.value), ...insureArray(addresses)],
    "id"
  );

  const handleChange = debounce((e) => setSearchTerm(e.target.value), 300);

  useEffect(() => {
    if (reset) {
      setAddress(emptyValue);
      setReset?.(false);
      setSearchTerm("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, setReset]);

  return (
    <Autocomplete
      fullWidth
      size="small"
      autoHighlight
      multiple={multiple}
      options={options}
      filterOptions={(options, { inputValue }) =>
        filterAddresses(options, inputValue)
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(opt) => opt.name}
      renderOption={(props, option) => (
        <li {...props} key={option.id} className={props.className + " group"}>
          <Typography noWrap>
            <span tw="whitespace-nowrap text-neutral-800 text-base">
              {`${getLabel(option)}`}
            </span>
            <span tw="whitespace-nowrap text-neutral-400 text-sm pl-2 group-hover:text-neutral-700">
              {formatAddress(option)}
            </span>
          </Typography>
        </li>
      )}
      value={address}
      onChange={(_, value) => {
        setAddress(value);
        onChange?.(value);
      }}
      componentsProps={{
        popper: {
          style: { width: "auto", maxWidth: "100%" },
          placement: "bottom-start",
        },
      }}
      renderInput={(params) => {
        return (
          <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <DefaultTextField
              {...params}
              ref={params.InputProps.ref}
              placeholder="Search for an address"
              onChange={handleChange}
              autoFocus={autoFocus}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading && <CircularProgress size={20} />}
                  </InputAdornment>
                ),
              }}
            />
          </form>
        );
      }}
      PaperComponent={(props) => (
        <PaperComponent
          isLoading={isLoading}
          optionCount={options.length}
          {...props}
        />
      )}
      {...props}
    />
  );
};

export default AddressSearch;
