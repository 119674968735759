import { ReactElement, ReactNode } from "react";

import Search from "./components/Search";
import filterActiveOcm from "./filters/filterActiveOcm";
import filterActiveOrderWindowSelector from "./filters/filterActiveOrderWindowSelector";
import filterAddresses from "./filters/filterAddresses";
import filterAnaplanProgramType from "./filters/filterAnaplanProgramType";
import filterBrands from "./filters/filterBrands";
import filterBudgets from "./filters/filterBudgets";
import filterBusinessUnit from "./filters/filterBusinessUnits";
import filterChannel from "./filters/filterChannel";
import filterCouponPrograms from "./filters/filterCouponPrograms";
import filterDateRange from "./filters/filterDateRange";
import filterFavorites from "./filters/filterFavorites";
import filterFiscalYears from "./filters/filterFiscalYears";
import filterInMarketMonths from "./filters/filterInMarketMonths";
import filterIsAccolade from "./filters/filterIsAccolade";
import filterItemTypes from "./filters/filterItemTypes";
import filterOrderCalendarMonths from "./filters/filterOrderCalendarMonths";
import filterOrderSetStatus from "./filters/filterOrderSetStatus";
import filterOrderStatus from "./filters/filterOrderStatus";
import filterOrderType from "./filters/filterOrderType";
import filterPoId from "./filters/filterPoId";
import filterPreOrderProgramBrand from "./filters/filterPreOrderProgramBrand";
import filterProgramCreators from "./filters/filterProgramCreators";
import filterProgramTypes from "./filters/filterProgramTypes";
import filterPrograms from "./filters/filterPrograms";
import filterPurchaseOrderStatus from "./filters/filterPurchaseOrderStatus";
import filterPurchaseOrderType from "./filters/filterPurchaseOrderType";
import filterPurchasers from "./filters/filterPurchasers";
import filterQuoteStatus from "./filters/filterQuoteStatus";
import filterRfpStatus from "./filters/filterRfpStatus";
import filterRuleType from "./filters/filterRuleType";
import filterStates from "./filters/filterStates";
import filterSubStates from "./filters/filterSubStates";
import filterSuppliers from "./filters/filterSuppliers";
import filterTerritories from "./filters/filterTerritories";
import filterTradeClass from "./filters/filterTradeClass";
import filterTriggeredRuleStatus from "./filters/filterTriggeredRuleStatus";
import filterUsers from "./filters/filterUsers";

type Filter = {
  name: string;
  uniqueId?: string;
  formControls?: string[];
  menuText?: ReactNode;
  Chip: (x: any) => ReactElement | null;
  Popper?: (x: any) => ReactElement | null;
  alwaysShow?: boolean;
  props?: Record<string, any>;
};

const makeFilterIndex = () => {
  const filters: Filter[] = [
    {
      name: "search",
      uniqueId: "q",
      Chip: (props) => <Search {...props} />,
      alwaysShow: true,
    },
    filterActiveOcm,
    filterActiveOrderWindowSelector,
    filterAddresses,
    filterAnaplanProgramType,
    filterBrands,
    filterBudgets,
    filterBusinessUnit,
    filterChannel,
    filterCouponPrograms,
    filterDateRange,
    filterFavorites,
    filterFiscalYears,
    filterInMarketMonths,
    filterIsAccolade,
    filterItemTypes,
    filterOrderCalendarMonths,
    filterOrderSetStatus,
    filterOrderStatus,
    filterOrderType,
    filterPreOrderProgramBrand,
    filterProgramCreators,
    filterPrograms,
    filterProgramTypes,
    filterPurchaseOrderStatus,
    filterPurchaseOrderType,
    filterPurchasers,
    filterQuoteStatus,
    filterRfpStatus,
    filterRuleType,
    filterStates,
    filterSubStates,
    filterSuppliers,
    filterTerritories,
    filterTradeClass,
    filterTriggeredRuleStatus,
    filterUsers,
    filterPoId,
  ];

  return filters.map((f) => ({
    ...f,
    uniqueId: f.uniqueId ?? f.name,
    formControls: f.formControls ?? [f.uniqueId ?? f.name],
  }));
};

const filterIndex = makeFilterIndex();

export default filterIndex;
