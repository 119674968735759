/** @jsxImportSource @emotion/react */
import "twin.macro";

import { TextInput } from "@components/Forms/ControlledInputs";

import FilterChip from "../components/FilterChip";
import { useFilterContext } from "../filterContext";

const Popper = ({ handleClose }) => {
  const { control } = useFilterContext();
  return (
    <div tw="p-6">
      <TextInput
        autoFocus
        control={control}
        color="primary"
        name="poId"
        label="Purchase Order #"
        rules={{
          pattern: {
            value: /^[\d,]*$/,
            message: "Only numbers and commas allowed",
          },
          transform: (v) => v.replace(/[^0-9,]/g, ""),
        }}
        helperText="(numeric only)"
        onKeyDown={(e) => e.code === "Enter" && handleClose?.()}
      />
    </div>
  );
};

const Chip = (props) => {
  const { filterValues } = useFilterContext();
  const poId = filterValues["poId"] ?? "";

  return (
    <FilterChip {...props} showCloseButton={poId.length > 0}>
      {poId.length > 0 && (
        <>
          <span tw="text-neutral-600">PO # is&nbsp;</span>
          <b>{poId}</b>
        </>
      )}
      {poId.length === 0 && <span tw="text-neutral-600">Enter PO #</span>}
    </FilterChip>
  );
};

const filterPoId = {
  name: "poId",
  formControls: ["poId"],
  menuText: "PO #",
  Popper,
  Chip: Chip,
};

export default filterPoId;
