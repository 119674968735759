import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { clearOrderVariantErrors } from "src/redux/slices/ordering/orderSetSlice";

import { ResourceError } from "@features/errors";
import { Order } from "@models/Order";
import { OrderSet } from "@models/OrderSet";

import OrderSetSkeleton from "../OrderSetSkeleton";
import { ExtendedOrderSetVariant, useOrderSet } from "./useOrderSet";
import useOrderSetId from "./useOrderSetId";

type onOrderSetDelete = (orderSet: OrderSet) => void;

type OrderSetContextType = {
  orderSet: OrderSet;
  orders: Order[];
  orderSetVariants: ExtendedOrderSetVariant[];
  orderSetId: string;
  isFetching: boolean;
  onOrderSetDelete: onOrderSetDelete;
};

const CurrentOrderSetContext = React.createContext<OrderSetContextType | null>(
  null
);

export const CurrentOrderSetContextProvider = ({
  children,

  onOrderSetDelete,
}: {
  children: React.ReactNode;
  onOrderSetDelete: onOrderSetDelete;
}) => {
  const dispatch = useDispatch();
  const orderSetId = useOrderSetId();
  const orderSetData = useOrderSet(orderSetId);

  useEffect(() => {
    if (!orderSetData.ordersQuery?.isFetching) {
      dispatch(clearOrderVariantErrors());
    }
  }, [dispatch, orderSetData.ordersQuery?.isFetching]);

  if (orderSetData.isLoading || orderSetData.isPending) {
    return <OrderSetSkeleton />;
  }

  if (orderSetData.error) {
    return <ResourceError error={orderSetData.error} />;
  }

  return (
    <CurrentOrderSetContext.Provider
      value={{
        orderSetId,
        orderSet: orderSetData.orderSet,
        orders: orderSetData.orders,
        orderSetVariants: orderSetData.orderSetVariants,
        isFetching: Boolean(
          orderSetData.orderSetQuery?.isFetching ||
            orderSetData.ordersQuery?.isFetching
        ),
        onOrderSetDelete,
      }}
    >
      {children}
    </CurrentOrderSetContext.Provider>
  );
};

export const useCurrentOrderSet = () => {
  const context = React.useContext(CurrentOrderSetContext);

  if (!context) {
    throw new Error(
      "useCurrentOrderSet must be used within a CurrentOrderSetContextProvider"
    );
  }

  return context;
};

export const useOptionalCurrentOrderSet = () => {
  return React.useContext(CurrentOrderSetContext);
};
